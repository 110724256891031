import React, { Fragment } from 'react'
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Background from '../../assets/images/home-hero.jpg'

const Careers = () => {
  return (
    <Fragment>
        <Navbar3/>
         <PageTitle pageTitle={'Jobs'} pagesub={'Jobs'} pageBackground={Background}  />
         <Footer/>
      
    </Fragment>
  )
}

export default Careers
