import React, {Fragment} from 'react';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer from '../../components/footer/Footer';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar from '../../components/Navbar/Navbar'
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.jpg'
import ServiceSectionS2 from '../../components/ServiceSectionS2/ServiceSectionS2';
import PartnerSectionS2 from '../../components/PartnerSection2/PartnerSectionS2';
import ProjectSection2 from '../../components/ProjectSection2/ProjectSection2';
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
import Hero2 from '../../components/hero2/Hero2';

const HomePage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-1'} topbarNone={'topbar-none'}/>
            <Hero2/>
            {/* <Hero/> */}
            <About abimg={abimg}/>
            <ServiceSectionS2/>
            <ServiceSection/>
            <FunFact/>
            <CtaSection/>
            <TeamSection/>
            <ProjectSection2/>
            {/* <Testimonial/> */}
            <Testimonial2/>
            <BlogSection/>
            
            <PartnerSection/>
            {/* <PartnerSectionS2/> */}
            <ContactInfo/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;