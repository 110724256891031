import React, { Fragment, useState } from 'react';
import { Form, FormControl, Table } from 'react-bootstrap';
import MembersList from '../../api/Members';
import '../../sass/styles.scss';

const Members = () => {
  // State for search query
  const [searchQuery, setSearchQuery] = useState('');

  // State for current page
  const [currentPage, setCurrentPage] = useState(1);

  // Number of items per page
  const itemsPerPage = 10;

  // Handler for search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when search query changes
  };

  // Filtered list of members based on search query
  const filteredMembers = searchQuery.trim() === ''
    ? MembersList
    : MembersList.filter((member) =>
        member.Name.toLowerCase().includes(searchQuery.toLowerCase())
      );

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembers.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total pages
  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);

  return (
    <Fragment>
      <div className="container members-table">
        <Form className="searchBar">
          <FormControl
            type="text"
            placeholder="Search Member"
            value={searchQuery}
            onChange={handleSearch}
          />
        </Form>
        <Table responsive>
          <thead>
            <tr>
              <th>Sno</th>
              <th>Logo</th>
              <th>Name</th>
              <th>Contact and Address Details</th>
              <th>Services</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((member) => (
              <tr key={member.id}>
                <td>{member.id}</td>
                <td className="member-logo">
                  <img src={member.logo} alt="member logo" />
                </td>
                <td>{member.Name}</td>
                <td>{member.address}</td>
                <td>{member.services}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ul className="pagination">
          {/* Previous Button */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {'<'}
            </button>
          </li>

          {/* Page Information */}
          <li className="page-item disabled">
            <span className="page-link">
              {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredMembers.length)} of {filteredMembers.length}
            </span>
          </li>

          {/* Next Button */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button
              className="page-link"
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {'>'}
            </button>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Members;
