import React, { Fragment } from 'react'
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Members from '../../components/Members/Members'
import Background from '../../assets/images/home-hero.jpg'

const MemberList = () => {
  return (
    <Fragment>
      <Navbar3/>
      <PageTitle pageTitle={'Members List'} pagesub={'Members List'} pageBackground={Background}  />
      <Members/>
      <Footer/>
    </Fragment>
  )
}

export default MemberList
