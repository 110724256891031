

const Steps = [
    {
        Id: '1',
        sTitle: 'Application form',
        description:"Duly filled BPOAK Application form. Can be downloaded here",
        icon:'flaticon-advisor',
    },       
    {
        Id: '2',
        sTitle: ' Incorporation Certificate ',
        description:"Certificate of Incorporation",
        icon:'flaticon-taxes',
    },
    {
        Id: '3',
        sTitle: 'Pin',
        description:"Company Kenya Revenue Authority PIN",
        icon:'flaticon-tax',
    },
    {
        Id: '4',
        sTitle: 'Directors',
        description:"List Of Directors of the company",
        icon:'flaticon-tax',
    },
    {
        Id: '5',
        sTitle: 'Trading Licence',
        description:"A Valid Trading Licence That Is Up To Date",
        icon:'flaticon-tax',
    },
    {
        Id: '6',
        sTitle: 'Company Profile',
        description:"Company Profile",
        icon:'flaticon-tax',
    },
    {
        Id: '7',
        sTitle: 'Application Fee',
        description:"Non refundable application fee of Kshs 00,000. Can be made by cheque or direct deposit to KSIA Account.",
        icon:'flaticon-tax',
    },
    {
        Id: '8',
        sTitle: 'Articles and Memorandum Of Association',
        description:"Articles and Memorandum Of Association",
        icon:'flaticon-tax',
    },
    {
        Id: '9',
        sTitle: 'Annual Subscription Fee',
        description:"This will be determined by the Governing Council based on the report and findings by the vetting team.",
        icon:'flaticon-tax',
    },
    
]    

export default Steps;