import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../assets/images/about-hero.jpg'
import shape1 from '../../images/about/shape-1.png'
import shape2 from '../../images/about/shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About2 = (props) => {
    return(
        <section className="wpo-about-section-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <div className="inner-img">
                                    <img src={abimg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h4>Welcome To BPOAK</h4>
                            <h2>We are trusted outsourcing company.</h2>
                            <p>BPOak is a leading provider of business process outsourcing solutions, committed to delivering excellence on a global scale. With a team of diverse professionals, we offer world-class services that cater to the unique needs of our clients .</p>
                            <div className="text-sub-wrap">
                                <div className="text-sub-item">
                                    <i className="fi flaticon-tax"></i>
                                    <h5>Outsource Smarter with BPOAk.</h5>
                                </div>
                                <div className="text-sub-item">
                                    <i className="fi flaticon-investor"></i>
                                    <h5>Your Partner in Progress</h5>
                                </div>
                            </div>
                            <Link onClick={ClickHandler} className="theme-btn" to="/service">Our Services</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1"><img src={shape1} alt=""/></div>
            <div className="shape-2"><img src={shape2} alt=""/></div>
        </section>
    )
}

export default About2;