import React, { Fragment } from 'react'
import Navbar3 from '../../components/Navbar3/Navbar3'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import Event from '../../components/Event-card/Event'
import Background from '../../assets/images/home-hero.jpg'

const Events = () => {
  return (
    <Fragment>
         <Navbar3/>
         <PageTitle pageTitle={'Events'} pagesub={'Events'} pageBackground={Background} />
         <Event/>
         <Footer/>
      
    </Fragment>
  )
}

export default Events
