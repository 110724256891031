// Import necessary modules
import { Card } from 'react-bootstrap/';
import React, { useEffect, useRef } from 'react';
import '../../sass/styles.scss'; // Ensure this path is correct for your setup
import Step from '../../api/Steps';

const Steps = () => {
  // Use ref to track card elements
  const cardRefs = useRef([]);

  useEffect(() => {
    // Callback function for IntersectionObserver
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
          observer.unobserve(entry.target); // Stop observing after it becomes visible
        }
      });
    };

    // Create a new IntersectionObserver
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1, // Trigger when 10% of the element is visible
    });

    // Observe each card
    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  return (
    <div className="container main-steps">
      <h2>How To Become A Member</h2>
      <div className="container member-steps">
        {Step.map((step, index) => (
          <Card
            key={step.Id}
            className="fade-in"
            ref={(el) => (cardRefs.current[index] = el)} // Set ref for each card
          >
            <Card.Header>
              <span>{step.Id}</span>
              {step.sTitle}
            </Card.Header>
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p>{step.description}</p>
                {/* <footer className="blockquote-footer">
                    Someone famous in <cite title="Source Title">Source Title</cite>
                </footer> */}
              </blockquote>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Steps;
        