import simg from '../assets/images/internet.jpeg'
import simg2 from '../images/service/2.jpg'
// import simg3 from '../images/service/3.jpg'
import simg3 from '../assets/images/contact.jpg'
import simg4 from '../assets/images/skills.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../assets/images/affordable.png'
import sImgS5 from '../assets/images/labour.jpeg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Faqs = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'High Quality Internet',
        description:"Kenya provides low-cost, high-speed internet connectivity throughout the East African region via eight submarine fiber optic cables.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-advisor',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },       
    {
        Id: '2',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: 'English Workforce',
        description:"Kenya has a large availability of highly skilled, English-speaking talent who speak with a more neutral accent making its citizens suitable for call center jobs and other client-facing outsourcing duties.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-taxes',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Digital Skills Workers',
        description:"Kenya's growing pool of skilled digital freelancers accredited to the Ajira Digital Program training. As of 2022, there were 1,933,011 million digital workers working either as freelancers or in BPOs",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Education Levels',
        description:"Kenyans are well-educated youth with at least 100,000 graduating from universities annually",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:sImgS5,
        sImgS:sImgS5,
        sTitle: 'Affordable cost labor',
        description:"The labor in Kenya is cheap and can potentially reduce the operational costs of global source market companies.",
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Faqs;