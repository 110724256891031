import React from "react";
import pimg1 from '../../assets/images/member1.png'
import pimg2 from '../../assets/images/member2.png'
import pimg3 from '../../assets/images/member3.png'
import pimg4 from '../../assets/images/member4.jpg'
import pimg5 from '../../assets/images/member5.jpg'
import pimg6 from '../../assets/images/member6.png'
import pimg7 from '../../assets/images/member7.png'
import pimg8 from '../../assets/images/member8.jpg'
import SectionTitle from "../SectionTitle/SectionTitle";

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
]


const PartnerSection = (props) => {
    return (
        <section className="partners-section section-padding pt-175">
            <div className="container">
                <SectionTitle subTitle={'HONORABLE MEMBERS'} Title={'We work with top brands'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="partner-grids clearfix">
                            {partners.map((partner, pitem) => (
                                <div className="grid" key={pitem}>
                                    <img src={partner.pImg} alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;
