import React, { Fragment } from 'react'
import { Button, Card } from 'react-bootstrap'
import logo from '../../assets/images/event1.jpeg'
import Events from '../../api/Events';

const Event = () => {
  return (
    <Fragment>
        <div className='container event'>
          {Events.map((event)=>(
              <Card >
              <Card.Img variant="top" src={logo} />
              <Card.Body>
                  <Card.Title>{event.title}</Card.Title>
                  <Card.Text>
                  {event.description}
                  </Card.Text>
                  <Card.Text>
                  {/* <i class="bi bi-calendar-date"></i> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-date-fill" viewBox="0 0 16 16">
                    <path d="M9.402 10.246c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5m9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5m-4.118 9.79c1.258 0 2-1.067 2-2.872 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684c.047.64.594 1.406 1.703 1.406zm-2.89-5.435h-.633A13 13 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675V7.354z"/>
                    </svg>
                  {event.date}
                  </Card.Text>
                  <Card.Text>
                  {/* <i class="bi bi-geo-alt-fill"></i> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                </svg>
                  {event.venue}
                  </Card.Text>
                  <Button variant="primary">{event.button}</Button>
              </Card.Body>
              </Card>
          ))}
            

        </div>
      
    </Fragment>
  )
}

export default Event
