import timg1 from '../assets/images/beanie.jpg'
import timg2 from '../assets/images/team2.jpg'
import timg3 from '../assets/images/team2.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Harry Abraham',
        title:'Founder',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Anne Johnson',
        title:'Admin',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Jenelia Orkid',
        title:'Marketing',
    }
]

export default Teams;