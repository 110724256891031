import bpoaklogo from '../assets/images/bpoak-logo.png'
import jumuisha from '../assets/images/jumuisha-logo.png'
import member2 from '../assets/images/member2.png'
import member3 from '../assets/images/member3.png'
import member4 from '../assets/images/member4.jpg'

const Members = [
    {
        id: 1,
        logo:bpoaklogo,
        Name:"InnovateX",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"InnovateX is a forward-thinking technology startup specializing in AI-powered solutions that transform how businesses operate. Our flagship product, InsightAI, leverages advanced machine learning algorithms to provide real-time analytics and predictive insights, empowering companies to make data-driven decisions with confidence. With a team of passionate innovators and a commitment to excellence, InnovateX is redefining the future of business intelligence."
    },
    {
        id: 2,
        logo:member2,
        Name:"BPOAK",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya"
    },
    {
        id: 3,
        logo:member3,
        Name:"BPOAK",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya"
    },
    {
        id: 4,
        logo:member4,
        Name:"BPOAK",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 5,
        logo:jumuisha,
        Name:"Jumuisha Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 6,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 7,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 8,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 9,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 10,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 11,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 12,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 13,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
    {
        id: 14,
        logo:bpoaklogo,
        Name:"Usalama Limited",
        address: "The Campus 36, Kabarsiran rd, off James Gichuru rd",
        services:"business processes outsourcing association of kenya,business processes outsourcing association of kenya,"
    },
]


export default Members;