import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Benefits = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'Cost Efficiency',
        description:"Labor Cost Savings: By outsourcing, companies can take advantage of lower labor costs in different geographical locations. This can result in significant savings compared to maintaining an in-house team, especially in regions where labor costs are high. BPOak operates in regions where labor costs are more economical, allowing businesses to benefit from these savings while still accessing a highly skilled workforce.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-advisor',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },       
    {
        Id: '2',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: ' Flexibility and scalability ',
        description:" Businesses can quickly adjust their level of service to meet changing demands, whether it involves scaling up during peak times or streamlining operations during slower periods. This adaptability ensures that companies receive customized solutions tailored to their specific goals and industry requirements, providing personalized attention and support at every step.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-taxes',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Innovation and competitive advantage',
        description:"By leveraging the latest technologies and best practices, BPOak helps businesses stay ahead of the competition with innovative solutions. Access to strategic insights and data analytics further informs business decisions and strategies, allowing companies to make well-informed decisions that drive success.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Benefits;