import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'

import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'




const Service = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'Destination Kenya Marketing',
        description:"To promote the BPO sector's domestic and international recognition and enhance its presence and competitiveness in new markets and emerging technologies.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-advisor',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },       
    {
        Id: '2',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: ' Enabling Sector Policy',
        description:"To provide a voice for the industry in lobbying regulatory and statutory bodies in order to create an enabling business environment for our members along the value chain.",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-taxes',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'Building Capacity & Infrastructure',
        description:"Working with relevant stakeholders to build capacity and infrastructure for the sector's sustainable growth",
        // des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        // des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Service;