import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/4.jpg'
// import shape1 from '../../images/ab-shape-1.png'
import image1 from '../../assets/images/hero2.jpg'
import shape2 from '../../images/ab-shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return(
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={image1} alt="about-img"/>
                                {/* <div className="wpo-ab-shape-1"><img src={image1} alt=""/></div>
                                <div className="wpo-ab-shape-2"><img src={image1} alt=""/></div> */}
                                <div className="wpo-about-exprience">
                                    <h3>___ Years Experience</h3>
                                </div>
                                <div className="wpo-ab-img-2">
                                    <img src={image1} alt="about-img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h4>Welcome To BPOAK</h4>
                            <h2>We are a trusted business process outsourcing company, <b>with <span>_____ Years</span> of experience.</b>
                            </h2>
                            <p>Welcome to BPOAK, where your business's success is our top priority. We specialize in providing world-class outsourcing solutions tailored to meet the unique needs of businesses across various industries. Our mission is to empower your organization with the tools, expertise, and support needed to thrive in a competitive market.</p>
                            <Link onClick={ClickHandler} className="theme-btn" to="/about">More About Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;