const Events= [
    {
        title:"Industry Networking Event",
        date:"10th october 2024",
        venue:"Nairobi, kenya",
        description:"An industry networking event brings together professionals from the BPO sector, potential clients, and industry experts. This event serves as a platform for sharing insights, discussing industry trends, and fostering relationships.",
        time:"10:00 am",
        button:"Get Location"
    },
    {
        title:"Client Appreciation Day",
        date:"10th october 2024",
        venue:"Nairobi, kenya",
        description:"A client appreciation event is dedicated to recognizing and thanking existing clients for their partnership and loyalty.",
        time:"10:00 am",
        button:"Get Location"
    },
    {
        title:"CSR and Community Engagement Event",
        date:"10th october 2024",
        venue:"Online, Google Meet",
        description:"A Corporate Social Responsibility (CSR) event focuses on giving back to the community and engaging employees in meaningful activities.",
        time:"10:00 am",
        button:"Join"
    },
    {
        title:"training",
        date:"10th october 2024",
        venue:"Nairobi, kenya",
        description:"training on data analysis",
        time:"10:00 am",
        button:"Get Location"
    },
]


export default Events;