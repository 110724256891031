import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'


const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-place"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <p>The Campus 36, Kabarsiran rd, off James Gichuru Rd, Nairobi, Kenya.</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <a style={{color:"black"}} href="mailto:info@bpoak.co.ke"> info@bpoak.co.ke</a>
                                            {/* <p>helloyou@gmail.com</p> */}
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <p>+254 7--------</p>
                                            <p>+254 7--------</p>
                                            {/* <p>+1 800 123 654 987</p> */}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>Have Any Question?</h2>
                            <p>Fill in this form or send us an e-mail with your inquiry.
                            </p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm/>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m15!1m11!1m3!1d496.8047735304554!2d36.77250893893091!3d-1.265445734679272!2m2!1f0!2f0!3m2!1i1024!2i768!4f13.1!2m1!1sKabarsiran%20rd%2C%20off%20James%20Gichuru%20Rd%20!5e1!3m2!1sen!2ske!4v1721823207509!5m2!1sen!2ske"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
