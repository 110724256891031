import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Footer from '../../components/footer/Footer';
import Benefits from '../../components/Benefits/Benefits';
import Steps from '../../components/membershipsteps.js/steps';
import CtaSection from '../../components/ctaSection/ctaSection';
import About from '../../components/about/about';
import membership from '../../assets/images/membership.jpg'
import Member from '../../components/member-hero/Member';
import Background from '../../assets/images/about.jpg'

const Membership = () => {
    return (
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'Membership'} pagesub={'Membership'} pageBackground={Background}  />
            {/* <About abimg={membership}/> */}
            {/* <Member abimg={membership}/> */}
            <Benefits/>
            <Steps/>
            <CtaSection/>
            <Footer/>
        </Fragment>
    );
}

export default Membership;
