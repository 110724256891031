import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../assets/images/slider1.png'
import hero2 from '../../assets/images/home-hero.jpg'


const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true
};

const Hero2 = () => {
    return (
        <section className="wpo-hero-slider">
            <div className="hero-container">
                <div className="hero-wrapper">
                    <Slider {...settings}>
                        <div className="hero-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title-sub">
                                            {/* <h5>Trusted Business Partner.</h5> */}
                                        </div>
                                        <div className="slide-title">
                                            <h2>At BPOAK,</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p> we seek to enhance sector recognition and competitiveness domestically and internationally. BPOAK envisions the industry becoming a sustainable pillar of Kenya’s economy, including creating quality jobs for its citizens.</p>
                                        </div>
                                        <div className="btns">
                                            <Link to="/about" className="theme-btn">Explore More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="hero-slide">
                            <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                                <div className="container-fluid">
                                    <div className="slide-content">
                                        <div className="slide-title-sub">
                                            {/* <h5>Trusted Business Partner.</h5> */}
                                        </div>
                                        <div className="slide-title">
                                            <h2>Our Vision</h2>
                                        </div>
                                        <div className="slide-text">
                                            <p>is to become the leading African BPO destination. In light of this, it seeks to collaboratively work with relevant stakeholders to improve the business climate for the sector’s sustainable growth. BPOAK is a member of the Kenya Private Sector Association (KEPSA)</p>
                                        </div>
                                        <div className="btns">
                                            <Link to="/about" className="theme-btn">Explore More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Hero2;