import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pimg1 from '../../assets/images/member1.png'
import pimg2 from '../../assets/images/member2.png'
import pimg3 from '../../assets/images/member3.png'
import pimg4 from '../../assets/images/member4.jpg'
import pimg5 from '../../assets/images/member5.jpg'
import pimg6 from '../../assets/images/member6.png'
import pimg7 from '../../assets/images/member7.png'
import pimg8 from '../../assets/images/member8.jpg'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
]

const settings = {
    dots: false,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const PartnerSectionS2 = (props) => {
    return (
        <section className={`partners-section-s2 section-padding ${props.tNone}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="partner-grids partners-slider owl-carousel">
                            <Slider {...settings}>
                                {partners.map((partner, pitem) => (
                                    <div className="grid" key={pitem}>
                                        <img src={partner.pImg} alt="" />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSectionS2;



// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import pimg1 from '../../images/partners/img-5.png'
// import pimg2 from '../../images/partners/img-8.png'
// import pimg3 from '../../images/partners/img-6.png'
// import pimg4 from '../../images/partners/img-7.png'
// import pimg5 from '../../images/partners/img-8.png'

// const partners = [
//     {
//         pImg: pimg1,
//     },
//     {
//         pImg: pimg2,
//     },
//     {
//         pImg: pimg3,
//     },
//     {
//         pImg: pimg4,
//     },
//     {
//         pImg: pimg5,
//     },
//     {
//         pImg: pimg1,
//     },
// ]

// const settings = {
//     dots: false,
//     arrows: false,
//     speed: 1000,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     responsive: [
//         {
//             breakpoint: 1500,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 1,
//             }
//         },
//         {
//             breakpoint: 1200,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 991,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1
//             }
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1
//             }
//         }
//     ]
// };


// const PartnerSectionS2 = (props) => {
//     return (
//         <section className={`partners-section-s2 section-padding ${props.tNone}`}>
//             <div className="container">
//                 <div className="row">
//                     <div className="col col-xs-12">
//                         <div className="partner-grids partners-slider owl-carousel">
//                             <Slider {...settings}>
//                                 {partners.map((partner, pitem) => (
//                                     <div className="grid" key={pitem}>
//                                         <img src={partner.pImg} alt="" />
//                                     </div>
//                                 ))}
//                             </Slider>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default PartnerSectionS2;